<template>
  <div>
    <!--  表单标题  -->
    <van-row type="flex" justify="center">
      <van-col span="20">
        <h1 style="text-align: center;">健康量表</h1>
      </van-col>
    </van-row>

    <!--  填写须知  -->
    <van-row type="flex" justify="center">
      <van-col span="20">
        <van-notice-bar
            wrapable
            :scrollable="false"
            text="健康量表只是对您的健康背景做简单了解，我们会针对您所填写的内容提供更为精准的健康指导方案，绝不涉及隐私泄露，请您悉知。"
        />
      </van-col>
    </van-row>



    <van-row type="flex" justify="center" style="margin-top: 15px;">
      <van-col span="20">
        <van-notice-bar
            wrapable
            :scrollable="false"
            color="#EE0A24"
            background="#ffffff"
            left-icon="info-o"
            :text="'样本编号：'+scode"
        />
      </van-col>
    </van-row>
    


    <!-- 表单  -->
    <van-row type="flex" justify="center">
      <van-col span="20">
        <van-form @submit="onSubmit">
           <van-row class="item-title" type="flex" justify="center">
            <van-col span="22">产品库信息</van-col>
          </van-row>

           <van-row class="item-title" justify="center">
              <van-field
              readonly
              required
              clickable
              v-model="discover_type"
              name="discover_type"
              @click="showDiscoverPicker = true"
              label="检测类型"
              placeholder="请填写检测类型"
               :error="false"
               :rules="[{ required: true, message: '请填写检测类型' }]"
            />
            <van-popup v-model="showDiscoverPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="testColumns"
                @cancel="showDiscoverPicker = false"
                @confirm="onConPickerGender"
            />
          </van-popup>
           <!-- <van-field
           readonly
           required
              clickable
              v-model="collection_type"
              name="collection_type"
              @click="showCollectionPicker = true"
              label="采集盒类型"
              placeholder="请填写采集盒类型"
               :rules="[{ required: true, message: '请填写采集盒类型' }]"

            />
            <van-popup v-model="showCollectionPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="testColumns1"
                @cancel="showCollectionPicker = false"
                @confirm="onConPickerGender1"
            />
          </van-popup> -->
           <van-field
           readonly
           required
              clickable
              v-model="sample_type"
              name="sample_type"
              @click="showSamplePicker = true"
              label="样本类型"
              placeholder="请填写样本类型"
               :error="false"
               :rules="[{ required: true, message: '请填写样本类型' }]"
              
            />
            <van-popup v-model="showSamplePicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="testColumns2"
                @cancel="showSamplePicker = false"
                @confirm="onConPickerGender2"
            />
          </van-popup>
        </van-row> 

          <van-row class="item-title" type="flex" justify="center">
            <van-col span="22">基本信息</van-col>
          </van-row>

          <!--姓名-->
          <van-field
             required
              v-model="name"
              name="name"
              label="姓名"
              placeholder="请填写姓名"
               :error="false"
              :rules="[{ required: true, message: '请填写姓名' }]"
          />

          <!--性别-->
          <van-field
          required
              readonly
              clickable
              name="gender"
              label="性别"
              v-model="gender"
              placeholder="选择性别"
              @click="showGenderPicker = true"
               :error="false"
              :rules="[{required:true,message:'请选择性别'}]"
          />
          <van-popup v-model="showGenderPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="genderColumns"
                @cancel="showGenderPicker = false"
                @confirm="onConfirmGender"
            />
          </van-popup>
           <!--出生日期-->
          <van-field
              readonly
              clickable
              name="birthday"
              required
              label="出生日期"
              v-model="birthdayLocale"
              placeholder="选择出生日期"
              @click="showBirthdayPicker = true"
               :error="false"
              :rules="[{required:true,message:'请选择出生日期'}]"
          />
          <van-popup v-model="showBirthdayPicker" round position="bottom">
            <van-datetime-picker
                v-model="birthday"
                type="date"
                title="选择出生日期"
                :max-date="maxDate"
                :min-date="minData"
                @cancel="showBirthdayPicker = false"
                @confirm="onConfirmBirthday"
            />
          </van-popup>
                 <!--手机号码-->
          <van-field
            v-show="this.phone_required"
             required
              v-model="phone"
              name="phone"
              label="手机号码"
              placeholder="请填写手机号码"
               :error="false"
              :rules="[{ validator:validatorPhone, message: '请输入正确的手机号' }]"
          />
          <van-field
            v-show="this.email_required"
             required
              v-model="email"
              name="email"
              label="邮箱"
              placeholder="请填写邮箱"
               :error="false"
              :rules="[{ validator:validatorEmail, message: '请输入正确的邮箱' }]"
          />
          <!--机构名称-->
          <van-field
          required
           readonly
           v-show="this.agency_required"
              v-model="agency"
              @click="showAgencies = true"
              name="agency"
              label="机构名称"
              placeholder="请填写机构名称"
               :error="false"
              :rules="[{validator:validatorAgency, message: '请选择机构名称！'}]"
          />
            <van-popup v-model="showAgencies" round position="bottom">
            <van-picker
                show-toolbar
                :columns="agencies"
                :columns-placeholder="['请选择', '请选择']"
                @cancel="showAgencies = false"
                @confirm="onShowAgencies"
            />
          </van-popup>
          <van-field v-model="remark"  name="remark" label="量表备注"  placeholder="请填写"/>
          <van-collapse  @change='changeValue' v-model="activeNames">
            <van-collapse-item title="选填项信息"  :value = titleValue name="1">
            <van-field
                readonly
                clickable
                name="nation"
                label="民族"
                v-model="nation"
                placeholder="选择民族(选填)"
                @click="showNationPicker = true"
            />
            <van-popup v-model="showNationPicker" round position="bottom">
              <van-picker
                  show-toolbar
                  :columns="nationColumns"
                  @cancel="showNationPicker = false"
                  @confirm="onConfirmNation"
              />
            </van-popup>

          

            <!--身高-->
            <van-field
                readonly
                clickable
                name="height"
                label="身高/cm"
                v-model="height"
                placeholder="选择身高(选填)"
                @click="showHeightPicker = true"
            />
            <van-popup v-model="showHeightPicker" round position="bottom">
              <van-picker
                  show-toolbar
                  :columns="heightColumns"
                  :default-index="160"
                  @cancel="showHeightPicker = false"
                  @confirm="onConfirmHeight"
              />
            </van-popup>

            <!--体重-->
            <van-field
                readonly
                clickable
                name="weight"
                label="体重/kg"
                v-model="weight"
                placeholder="选择体重(选填)"
                @click="showWeightPicker = true"
            />
            <van-popup v-model="showWeightPicker" round position="bottom">
              <van-picker
                  show-toolbar
                  :columns="weightColumns"
                  :default-index="49"
                  @cancel="showWeightPicker = false"
                  @confirm="onConfirmWeight"
              />
            </van-popup>

            <!--祖籍-->
            <van-field
                readonly
                clickable
                name="ancestralHome"
                label="祖籍"
                v-model="ancestralHome"
                placeholder="选择祖籍(选填)"
                @click="showAncestralHomePicker = true"
            />
            <van-popup v-model="showAncestralHomePicker" round position="bottom">
              <van-area
                  title="标题"
                  :area-list="areaList"
                  :columns-placeholder="['请选择', '请选择', '请选择']"
                  @cancel="showAncestralHomePicker = false"
                  @confirm="onConfirmAncestralHome"
              />
            </van-popup>

            <!--常住地-->
            <van-field
                readonly
                clickable
                name="address"
                label="常住地"
                v-model="address"
                placeholder="请选择常住地(选填)"
                @click="showAddressPicker = true"
            />
            <van-popup v-model="showAddressPicker" round position="bottom">
              <van-area
                  title="标题"
                  :area-list="areaList"
                  :columns-placeholder="['请选择', '请选择', '请选择']"
                  @cancel="showAddressPicker = false"
                  @confirm="onConfirmAddress"
              />
            </van-popup>

    

            <!--身份证号-->
            <van-field
                v-model="idCard"
                name="idCard"
                label="身份证号码"
                placeholder="请填写身份证号码(选填)"
                :rules="[{validator, message: '身份证号码格式错误！'}]"
            />
            
            <van-row class="item-title" type="flex" justify="center">
              <van-col span="22">过敏史</van-col>
            </van-row>
          
            <van-field name="radioAllergy" label="有无过敏史">
            <template #input>
              <van-radio-group v-model="radioAllergy" direction="horizontal">
                <van-radio name="1">无</van-radio>
                <van-radio name="2">有</van-radio>
              </van-radio-group>
            </template>
          </van-field>


            <!--药物过敏-->
            <div v-if="this.radioAllergy == '2'">
            <van-field  name="drugs" label="药物过敏">
              <template #input>
                <van-checkbox-group v-model="drugs">
                  <van-checkbox style="padding: 5px;" v-for="(v,k) in drugAllergy" :key="k" :name="v" shape="square">{{
                      v
                    }}
                  </van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
                v-model="otherDrugs"
                name="otherDrug"
                label="其它过敏药物"
                placeholder="请输入其它过敏药物"
            />

            <!--食物过敏-->
            <van-field name="foods" label="食物过敏">
              <template #input>
                <van-checkbox-group v-model="foods">
                  <van-checkbox style="padding: 5px;" v-for="(v,k) in foodAllergy" :key="k" :name="v" shape="square">{{
                      v
                    }}
                  </van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <van-field
                v-model="otherFoods"
                name="otherFoods"
                label="其它过敏食物"
                placeholder="请输入其它过敏食物"
            />
            </div>

            <van-row class="item-title" type="flex" justify="center">
              <van-col span="22">既往病史</van-col>
            </van-row>
            
            <van-field name="medicalHistory" label="有无既往病史">
            <template #input>
              <van-radio-group v-model="medicalHistory" direction="horizontal">
                <van-radio name="1">无</van-radio>
                <van-radio name="2">有</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div v-if="this.medicalHistory == '2'"> 
            <!--既往病史1-->
            <van-row class="disease-title" type="flex" justify="center">
              <van-col span="22">既往病史 - 1</van-col>
            </van-row>
            <van-field
                v-model="disease1"
                name="disease1"
                label="疾病名称"
                placeholder="请输入疾病名称"
            />
            <van-field
                v-model="diseaseAge1"
                name="diseaseAge1"
                label="患病年龄"
                placeholder="请输入患病年龄"
            />
            <van-field
                v-model="diseaseStatus1"
                name="diseaseStatus1"
                label="愈后情况"
                placeholder="请输入愈后情况"
            />

            <!--既往病史2-->
            <van-row class="disease-title" type="flex" justify="center">
              <van-col span="22">既往病史 - 2</van-col>
            </van-row>
            <van-field
                v-model="disease2"
                name="disease2"
                label="疾病名称"
                placeholder="请输入疾病名称"
            />
            <van-field
                v-model="diseaseAge2"
                name="diseaseAge2"
                label="患病年龄"
                placeholder="请输入患病年龄"
            />
            <van-field
                v-model="diseaseStatus2"
                name="diseaseStatus2"
                label="愈后情况"
                placeholder="请输入愈后情况"
            />
            


            <!--既往病史3-->
            <van-row class="disease-title" type="flex" justify="center">
              <van-col span="22">既往病史 - 3</van-col>
            </van-row>
      
            <van-field
                v-model="disease3"
                name="disease3"
                label="疾病名称"
                placeholder="请输入疾病名称"
            />
            <van-field
                v-model="diseaseAge3"
                name="diseaseAge3"
                label="患病年龄"
                placeholder="请输入患病年龄"
            />
            <van-field
                v-model="diseaseStatus3"
                name="diseaseStatus3"
                label="愈后情况"
                placeholder="请输入愈后情况"
            />
            </div>
  
            <van-row class="item-title" type="flex" justify="center">
              <van-col span="22">家族病史</van-col>
            </van-row>

            <van-field name="radioFamily" label="有无家族病史">
                <template #input>
                  <van-radio-group v-model="radioFamily" direction="horizontal">
                    <van-radio name="1">无</van-radio>
                    <van-radio name="2">有</van-radio>
                  </van-radio-group>
                </template>
              </van-field>
          <div v-if="this.radioFamily == '2'"> 
              <van-notice-bar
                  wrapable
                  :scrollable="false"
                  text="请注明疾病名称和亲缘关系，如：长期偏头痛/母亲，肿瘤或其他遗传疾病请注明病种和发病年龄。"
              />
              <!--父系家族病史-->
              <van-field
                  v-model="parentDiseases"
                  rows="2"
                  :autosize="true"
                  name="parentDiseases"
                  label="父系家族"
                  type="textarea"
                  placeholder="请输入父系家族病史"
              />
              <!--母系家族病史-->
              <van-field
                  v-model="motherDiseases"
                  rows="2"
                  :autosize="true"
                  name="motherDiseases"
                  label="母系家族"
                  type="textarea"
                  placeholder="请输入母系家族病史"
              />
              <!--兄弟姐妹家族病史-->
              <van-field
                  v-model="brotherDiseases"
                  rows="2"
                  :autosize="true"
                  name="brotherDiseases"
                  label="兄弟姐妹"
                  type="textarea"
                  placeholder="请输入兄弟姐妹家族病史"
              />
              <!--子女病史-->
              <van-field
                  v-model="childDiseases"
                  rows="2"
                  :autosize="true"
                  name="childDiseases"
                  label="子女"
                  type="textarea"
                  placeholder="请输入子女病史"
              />
          </div>

            </van-collapse-item>
          </van-collapse>
          <!--民族-->
          
       

          <!--监护人信息-->
          <van-notice-bar
              wrapable
              :scrollable="false"
              text="如申请人年龄未满18岁，需填写以下信息"
              style="margin-top: 15px;"
          />
          <van-field
              v-model="guardianName"
              name="guardianName"
              label="监护人姓名"
              placeholder="请输入监护人姓名"
          />
          <van-field
              v-model="guardianRelation"
              name="guardianRelation"
              label="与申请人关系"
              placeholder="请输入监护人与申请人的关系"
          />
          <van-field class="checkbox" name="checkbox"  @click="showBooks">
            <template #input>
              <van-checkbox v-model="checked"><div class="booksBut">本人或监护人已阅读并知晓知情同意书相关条款</div></van-checkbox>
            </template>
          </van-field>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">
              提交
            </van-button>
          </div>
        </van-form>
      </van-col>
    </van-row>
   
   <van-popup :close-on-click-overlay="false" v-model="showTime" round :style="{ width: '90%',height:'80%' }">
       <Books></Books>
       <div style="text-align: center;">
        <van-button v-if="butShow"  style="width:90%;margin-bottom:0.2rem"  disabled round type="info">
          <van-count-down  :time="time" @finish = finish format="ss" >
           <template #default="timeData">
            <span style="color:#fff">已知晓上述内容 (<span class="block">{{ timeData.seconds }}</span>) </span>
          </template>
          </van-count-down>
          </van-button>
        <van-button v-else style="width:90%;margin-bottom:0.2rem" @click="infoClick" round type="info">已知晓上述内容</van-button>
        </div>
   </van-popup>
    <van-dialog v-model="showDialog"
                title="提示"
                :message="dialogMessage"
                :show-cancel-button="false"
                :show-confirm-button="false"
                :overlayStyle="{background:'#f7f8fa'}"
    >
    </van-dialog>
  </div>
</template>

<script>
import Books from "./books.vue"
import areaList from '../assets/area'
import { Dialog, RadioGroup, Radio ,CountDown , Collapse, CollapseItem} from "vant"


export default {
  components:{
    Books,
    [CountDown.name]:CountDown,
     [RadioGroup.name]:RadioGroup,
     [Radio.name]:Radio,
     [Collapse.name]:Collapse,
     [CollapseItem.name]:CollapseItem,
  },
  name: 'Form',
  data() {
    return {
      titleValue:'关闭更多',
      activeNames:['1'],
      agency:'',
      showAgencies:false,
      agency_required:false,
      phone_required:false,
      email_required:false,
      agencies:[],
      butShow:true,
      time:  5000,
      showTime:false,
      checked:false,
      radioFamily:'1',
      medicalHistory:'1',
      radioAllergy:'1',
      discover_type:'',//检测类型
    
      sample_type:'',//样本类型
       discover_types:[],//检测类型
    
      sample_types:[],//样本类型
      minData: new Date(1900, 0, 1),
       maxDate: new Date(),
      scode: this.$route.query.code,
      name: '',// 姓名
      gender: '',// 性别
      showGenderPicker: false,// 性别picker开关
      showDiscoverPicker: false,// 性别picker开关
      showCollectionPicker: false,// 性别picker开关
      showSamplePicker: false,// 性别picker开关
      genderColumns: ['男', '女'],// 性别数据
      testColumns:[],
      testColumns1:[],
      testColumns2:[],
      remark:'',//量表备注
      nation: '',// 民族
      showNationPicker: false,// 民族picker开关
      nationColumns: [
        '汉族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '壮族', '布依族', '朝鲜族', '满族', '侗族', '瑶族', '白族',
        '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '傈僳族', '佤族', '畲族', '拉祜族', '水族', '东乡族', '纳西族', '景颇族',
        '柯尔克孜族', '土族', '达斡尔族', '仫佬族', '羌族', '布朗族', '撒拉族', '毛南族', '仡佬族', '锡伯族', '阿昌族', '普米族',
        '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族', '鄂温克族', '德昂族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族',
        '鄂伦春族', '赫哲族', '门巴族', '珞巴族', '基诺族', '高山族',
      ],// 民族数据
      birthdayLocale: '',// 格式化后的出生日期
      birthday: new Date(),// 出生日期
      showBirthdayPicker: false,// 出生日期picker开关
      areaList: areaList,// 省市区数据
      ancestralHome: '',// 祖籍
      showAncestralHomePicker: false,// 祖籍picker开关
      height: null,// 身高
      showHeightPicker: false,// 身高picker开关
      heightColumns: [],// 身高数据
      weight: null,// 体重
      showWeightPicker: false,// 体重picker开关
      weightColumns: [],// 体重数据
      phone: '',// 手机号码
      email:'',
      address: '',// 常住地
      showAddressPicker: false,// 常住地picker开关
      idCard: '',// 身份证号
      drugs: [],// 过敏药物
      drugAllergy: ['青霉素', '磺胺类', '链霉素'],// 过敏药物数据
      otherDrugs: '',// 其他过敏药物
      foods: [],// 过敏食物
      foodAllergy: ['花生', '海鲜', '麦麸过敏'],// 过敏食物数据
      otherFoods: '',// 其他过敏食物
      disease1: '',// 既往病史1疾病名称
      diseaseAge1: '',// 既往病史1患病年龄
      diseaseStatus1: '',// 既往病史1愈后情况
      disease2: '',// 既往病史2疾病名称
      diseaseAge2: '',// 既往病史2患病年龄
      diseaseStatus2: '',// 既往病史2愈后情况
      disease3: '',// 既往病史3疾病名称
      diseaseAge3: '',// 既往病史3患病年龄
      diseaseStatus3: '',// 既往病史3愈后情况
      parentDiseases: '',// 父系家族病史
      motherDiseases: '',// 母系家族病史
      brotherDiseases: '',// 兄弟姐妹家族病史
      childDiseases: '',// 子女病史
      guardianName: '',// 监护人姓名
      guardianRelation: '',// 监护人与申请人的关系
 
      showDialog: false,// 弹窗开关
      dialogMessage: '测试消息',// 弹窗消息
    };
  },
  methods: {
    changeValue(){
       if (this.titleValue == '展开更多') {
   

         this.titleValue = '关闭更多'
       }else{
            console.log('132132');
         this.titleValue = '展开更多'
       }
       
    },
    finish(){
        this.butShow = false
    },
    showBooks(){
      this.showTime = true

    },
     infoClick(){
          //  console.log(123);
        this.showTime = false
        this.checked = true
    },
    /**
     * 手机号验证
     */
    validatorAgency(val){
      console.log(val);
       if (this.agency_required) {
          if (val == '') {
            return false
          }else{
            return true
          }  
       }else{
         return true
       }
    },
    validatorPhone(val){
      const card = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (this.phone_required) {
        return card.test(val)
      }else{
        return true
      }
      
    },
    validatorEmail(val){
      const regx = /^([A-Za-z0-9_\-.\u4e00-\u9fa5])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,8})$/;
      if (this.email_required) {
        return regx.test(val)
      }else{
        return true
      }
    },
    /**
     * 身份证验证
     */
    validator (val) {
      const card18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
       if (val === "") {
        return true
      } else {
        return card18.test(val)
      }

    },

    // 提交数据
    onSubmit(values) {
      console.log(values)
       let diff = new Date().getTime() - new Date(values.birthday).getTime()
        // field.age = Math.floor(diff / (24 * 3600 * 1000) / 365)
        let ages = Math.ceil(diff / (24 * 3600 * 1000) / 365)
        console.log(ages);
      if (!this.checked) {
        Dialog.alert({
          title: '提示',
          message: '请阅读知情同意书',
        })
      }else if( ages < 18 && !values.guardianName){
         Dialog.alert({
          title: '提示',
          message: '未满18岁，请完善监护人信息',
        })
      } else if (ages < 18 && !values.guardianRelation) {
        Dialog.alert({
          title: '提示',
          message: '未满18岁，请完善监护人信息',
        })
      }else{
        let field = {}
        field.agency = this.agency
        field.code = this.scode;
        for (let i = 0; i < this.discover_types.length; i++) {
          if (this.discover_types[i].client === values.discover_type) {
            field.discover_type = this.discover_types[i].gk
          }
        }
 
        for (let i = 0; i < this.sample_types.length; i++) {
          if (this.sample_types[i].client === values.sample_type) {
            field.sample_type = this.sample_types[i].gk
          }
        }
        field.name = values.name;
        if (values.gender === '男') {
          field.gender = 'male';
        } else {
          field.gender = 'female';
        }
        field.remark = values.remark;
        field.nation = values.nation;
        field.birthday = values.birthday;
        let diff = new Date().getTime() - new Date(values.birthday).getTime()
        // field.age = Math.floor(diff / (24 * 3600 * 1000) / 365)
        let ages = Math.ceil(diff / (24 * 3600 * 1000) / 365)
        if (ages < 1) {
          field.age = 1
        }else{
          field.age = ages 
        }
        field.height = values.height;
        field.weight = values.weight;
        field.ancestral_home = values.ancestralHome;
        field.address = values.address;
        field.phone = values.phone;
        field.email = values.email;
        field.id_card = values.idCard;

        // 过敏药物
        field.allergy_drugs = JSON.stringify(values.drugs);
        field.other_allergy_drugs = JSON.stringify(this.otherDrugs)
        // 过敏食物
        field.allergy_foods = JSON.stringify(values.foods);
        field.other_allergy_foods = JSON.stringify(this.otherFoods)


        // 既往病史
        let diseases = [
          {name: values.disease1, age: values.diseaseAge1, status: values.diseaseStatus1},
          {name: values.disease2, age: values.diseaseAge2, status: values.diseaseStatus2},
          {name: values.disease3, age: values.diseaseAge3, status: values.diseaseStatus3},
        ]
        field.diseases = JSON.stringify(diseases);

        // 家族病史
        let familyDiseases = {
          parent: values.parentDiseases,
          mother: values.motherDiseases,
          brother: values.brotherDiseases,
          child: values.childDiseases
        }
        field.family_diseases = JSON.stringify(familyDiseases);

        field.guardian_name = values.guardianName;
        field.guardian_relation = values.guardianRelation;
        
        const self = this;
        console.log(field)
        this.$axios.post('https://service.genekang.com/end_user/save', field).then((res) => {
        // this.$axios.post('/api/end_user/save', field).then((res) => {
          if (String(res.data.status) === 'success') {
            Dialog.alert({   
              message: '健康量表信息提交成功',
            }).then(() => { 
              this.$router.push({path:'/'})              
            });
          
          }else{
            Dialog.alert({   
              message: res.data.message,
            })
          }
        }).catch(() => {
          self.$toast.fail('网络错误，请稍后再试');
        })
      }
     
    },
    onConPickerGender(discover_type){
      this.discover_type = discover_type
      this.showDiscoverPicker = false
    },
   
    onConPickerGender2(sample_type){
      this.sample_type = sample_type
      this.showSamplePicker = false
    },
    // gender picker
    onConfirmGender(gender) {
      this.gender = gender;
      this.showGenderPicker = false;
    },

    // nation picker
    onConfirmNation(nation) {
      this.nation = nation;
      this.showNationPicker = false;
    },
    onShowAgencies(agency){
       this.agency = agency[0] +' '+agency[1]
       this.showAgencies = false
    },

    // birthday picker
    onConfirmBirthday(birthday) {
      let year = birthday.getFullYear();
      let month = birthday.getMonth() + 1;
      if (month < 10) {
        month = '0' + month
      }
      let day = birthday.getDate();
      this.birthdayLocale = year + '/' + month + '/' + day;
      this.showBirthdayPicker = false;
    },

    // ancestralHome picker
    onConfirmAncestralHome(value) {
      if (!value[0].name) {
        return
      }
      let province = value[0].name;
      if (!value[1].name) {
        return
      }
      let city = value[1].name;
      if (!value[2].name) {
        return
      }
      let area = value[2].name;
      this.ancestralHome = province + ' ' + city + ' ' + area
      this.showAncestralHomePicker = false;
    },

    // height picker
    onConfirmHeight(height) {
      this.height = height;
      this.showHeightPicker = false;
    },

    // weight picker
    onConfirmWeight(weight) {
      this.weight = weight;
      this.showWeightPicker = false;
    },

    // address picker
    onConfirmAddress(value) {
      if (!value[0].name) {
        return
      }
      let province = value[0].name;
      if (!value[1].name) {
        return
      }
      let city = value[1].name;
      if (!value[2].name) {
        return
      }
      let area = value[2].name;
      this.address = province + ' ' + city + ' ' + area
      this.showAddressPicker = false;
    },
    getConfig(){
        let data = {
          code : this.scode
      }
        this.$axios.post('https://service.genekang.com/end_user/get_config',data).then((res) => {
        // this.$axios.post('/api/end_user/get_config',data).then((res) => {
//  [{text: "123", children: [{text:'156315'},{text:'123'},{text:'789'}]}]
            let arr2 = []
            let arr = res.data.data.agencies
            this.agency_required = res.data.data.agency_required
            this.phone_required = res.data.data.phone_required
            this.email_required = res.data.data.email_required
            arr.map(item=>{
                arr2.push({
                  text:item.name,
                  children:item.nodes
              })
            })
            console.log(arr2)
            arr2.forEach((item)=>{
              console.log(item.children)
              if(item.children.length>0){
                item.children.forEach((k,i)=>{
                  let obj = {
                    text:k
                  }
                  item.children[i] = obj
                })
              }else{
                let obj = {
                  text:''
                }
                item.children[0] = obj
              }
            })
            console.log(arr2)
            this.agencies = arr2
          })
    },
    getce(){
       let data = {
          code : this.scode
      }
     this.$axios.post('https://service.genekang.com/end_user/get_options',data).then((res) => {
        // this.$axios.post('/api/end_user/get_options',data).then((res) => {
  
        this.discover_types = res.data.data.discover_type
        this.sample_types = res.data.data.sample_type
        
         for (let i = 0; i < res.data.data.sample_type.length; i++) {
         
          this.testColumns2.push (res.data.data.sample_type[i].client)
        }
         for (let i = 0; i < res.data.data.discover_type.length; i++) {
         
          this.testColumns.push (res.data.data.discover_type[i].client)
        }
      })
    }
  },
  mounted() {
    this.getConfig()
    this.getce()
    // 生成身高数据
    for (let i = 10; i < 300; i++) {
      this.heightColumns.push(i)
    }

    // 生成体重数据
    for (let i = 1; i < 300; i++) {
      this.weightColumns.push(i)
    }
  },
  // created() {
  //   // 校验URL
  //   let query = document.URL.split('?')[1]
  //   const self = this;
  //   this.$axios.get('verify?' + query).then((res) => {

  //     if (String(res.status) === 'fail') {
  //       self.dialogMessage = res.message;
  //       self.showDialog = true;
  //     }
  //     let params = new URLSearchParams(new URL(document.URL).search)
  //     self.scode = params.get('scode')
  //   }).catch(() => {
  //     self.dialogMessage = '网络错误，请稍后再试';
  //     self.showDialog = true;
  //   })
  // }
}
</script>
<style>

  .block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }
#home{
  font-size: 16px;
}
.item-title {
  background: #ffffff;
  margin: 15px 0 15px 0;
  padding: 10px 0 10px 0;
  border-radius: 5px;
}

.disease-title {
  background: #ffffff;
  padding: 10px 0 10px 0;
}
</style>