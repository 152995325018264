<template>
  <div class="index">
    <div class="aaa" :style="status ? 'display:none;' : ''">
      <div class="box">
        <van-cell-group class="inputBox">
          <van-field
            class="input"
            v-model="value"
            label=""
            placeholder="输入送检样本的编号"
            style="color: #fff"
          />
        </van-cell-group>
      </div>
      <div class="action-box">
        <div class="item scan" @click="barcodeModalState = true">
          <div class="text">扫一扫</div>
        </div>
        <div class="item image">
          <input
            id="pictureD_file"
            class="input-code"
            accept="image/*"
            type="file"
            name="pictureD_file"
            @change="handleFiles($event)"
          />
          <div class="text">图片</div>
        </div>
        <div class="item confirm" @click="clickEnd">
          <div class="text">确认</div>
        </div>
      </div>
    </div>
    <div class="tip">
      <p style="font-weight: 550">温馨提示：</p>
      <p>请保持条形码平整，并正对条形码进行扫描；</p>
      <p>若未识别成功，请手动输入送检样本的编号。</p>
    </div>
    <van-loading
      class="bbb"
      :style="status === false ? 'display:none;' : ''"
      size="24px"
      >识别中...</van-loading
    >
    <CameraScanner
      :open-modal.sync="barcodeModalState"
      :camera-details.sync="cameraDetails"
      :raw-result.sync="rawResult"
    />
  </div>
</template>
<script>
import { Uploader, Dialog, Loading, Icon } from "vant";
import Quagga from "quagga"; // 条形码 import QRCode from 'qrcodejs2'
import CameraScanner from "@/components/CameraScanner.vue";

export default {
  components: {
    [Uploader.name]: Uploader,
    [Dialog.name]: Dialog,
    [Loading.name]: Loading,
    [Icon.name]: Icon,
    CameraScanner,
  },
  data() {
    return {
      shows: true,
      value: "",
      status: false,

      barcodeModalState: false,
      cameraDetails: {},
      rawResult: {},
    };
  },
  watch: {
    rawResult(newVal) {
      if (newVal.text) {
        this.value = newVal.text;
      }
    },
  },
  methods: {
    // 提交
    clickEnd() {
      let data = {
        code: this.value,
      };
      Dialog.alert({
        message:
          "绑定的编号信息为“" + this.value + "”请确认绑定，一旦绑定不能修改",
        showCancelButton: true,
      }).then(() => {
        this.$axios
          .post("https://service.genekang.com/end_user/bind", data)
          .then((res) => {
            // this.$axios.post("/api/end_user/bind", data).then((res) => {
            if (!res.data.message) {
              Dialog.alert({
                message: "绑定成功",
              }).then(() => {
                this.$router.push({
                  path: "/home",
                  query: { code: this.value },
                });
              });
            } else {
              Dialog.alert({
                message: res.data.message,
              }).then(() => {});
            }
          });
      });
    },

    // 图片识别
    handleFiles($event) {
      this.status = true;
      let files = $event.target.files[0];
      let _this = this;
      if (files) {
        let reader = new FileReader();
        reader.readAsDataURL(files);
        reader.onloadend = function () {
          let img = new Image();
          img.src = reader.result;
          _this.init(img.src); // init函数，路径为参数
        };
      }
    },
    init(src) {
      let _this = this;
      const config = {
        locator: {
          patchSize: "medium",
          halfSample: true,
        },
        numOfWorkers: navigator.hardwareConcurrency
          ? navigator.hardwareConcurrency
          : 4,
        decoder: {
          readers: [
            "code_128_reader",
            "ean_reader",
            "ean_8_reader",
            "code_39_reader",
            "code_39_vin_reader",
            "codabar_reader",
            "upc_reader",
            "upc_e_reader",
            "i2of5_reader",
          ],
        },
        locate: true,
        src: src,
      };
      Quagga.decodeSingle(config, function (result) {
        console.log(this, result);
        if (!result) {
          _this.status = false;
          alert("图片中没有条形码!");
          return false;
        }
        //识别结果
        if (result.codeResult) {
          _this.status = false;
          // localStorage.setItem("barcode", result.codeResult.code);
          _this.value = result.codeResult.code;
          window.location.reload();
          _this.quaCode = result.codeResult.code;
        } else {
          _this.status = false;
          alert("未识别图片中条形码!");
        }
      });
    },
  },
  // created() {
  //   localStorage.setItem("barcode", "");
  // },
};
</script>
<style>
html,
body,
#app,
.index {
  height: 100%;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none !important;
}
.aaa {
  display: block;
  padding-top: 230px;
}
.bbb {
  padding-top: 300px;
  text-align: center;
}
.inputBox .van-field__control {
  color: #ffffff;
}
</style>
<style scoped>
.inputBox {
  background: none;
}
.index {
  background: url(../assets/imgs/back.png);
  background-size: 100% 100%;
}

.input {
  background: url(../assets/imgs/input.png);
  background-size: 100% 100%;
  opacity: 0.8;
  color: #fff !important;
}
.box {
  height: 100%;
  padding: 0 30px;
}

/* 操作区域 */
.action-box {
  margin-top: 30px;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}
.action-box .item {
  width: 51px;
  height: 51px;
  color: #fff;
  text-align: center;
  padding: 0 12px 12px;
  position: relative;
}
.action-box .item .input-code {
  width: 51px;
  height: 51px;
  opacity: 0;
}

.action-box .item .text {
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translate(-50%);
  font-size: 16px;
  width: 60px;
  text-align: center;
}

.action-box .scan {
  background: url(../assets/imgs/scan.png) no-repeat;
  background-size: 100% 100%;
}
.action-box .image {
  left: 0;
  background: url(../assets/imgs/image.png) no-repeat;
  background-size: 100% 100%;
}
.action-box .confirm {
  left: 0;
  background: url(../assets/imgs/confirm.png) no-repeat;
  background-size: 100% 100%;
}

.tip {
  margin-top: 2.5rem;
  color: #fff;
  text-align: center;
  font-size: 0.37333rem;
}
.barcode-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}
.barcode-container .close-pic {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  padding: 6px;
  border: 1px solid #fff;
  border-radius: 50%;
}
</style>