import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {Col, Row} from "vant";
import {Form} from "vant";
import {Field,Button} from "vant";
import {Picker} from "vant";
import {Popup} from "vant";
import {DatetimePicker} from "vant";
import {Area} from "vant";
import {CheckboxGroup,Checkbox,CellGroup,Cell} from "vant";
import {NoticeBar} from "vant";
import {Dialog} from "vant";
import {Toast} from "vant";
import axios from "axios"
import 'amfe-flexible'

Vue.config.productionTip = false

Vue.use(Col)
Vue.use(Row)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(DatetimePicker)
Vue.use(Area)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(NoticeBar)
Vue.use(Dialog)
Vue.use(Toast)

Vue.prototype.$axios = axios


new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
