var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modalState)?_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"controls"},[_c('div',{staticClass:"title"},[_vm._v("准备扫描...")]),(_vm.loaded)?_c('div',[_c('div',{class:{
          disabled: _vm.videoDevices.devices.length < 2,
        },on:{"click":_vm.switchInputDevice}},[_c('SvgIcon',{attrs:{"type":"mdi","path":_vm.videoDevices.devices.length < 2
              ? _vm.icons.mdiCamera
              : _vm.icons.mdiCameraSwitch}})],1)]):_vm._e(),(_vm.loaded)?_c('div',[_c('div',{class:{
          disabled: !_vm.hasTorch,
          activated: _vm.hasTorch && _vm.torch,
        },on:{"click":function($event){_vm.torch = !_vm.torch}}},[_c('SvgIcon',{attrs:{"type":"mdi","path":_vm.torch ? _vm.icons.mdiLightbulbOn : _vm.icons.mdiLightbulbOutline}})],1)]):_vm._e(),_c('div',[_c('div',{staticClass:"close",on:{"click":_vm.modalClose}},[_c('SvgIcon',{attrs:{"type":"mdi","path":_vm.icons.mdiCloseThick}})],1)])]),_c('div',{staticClass:"barcode-container"},[_c('StreamBarcodeReader',{attrs:{"videoDevices":_vm.videoDevices,"hasFocusDistance":_vm.hasFocusDistance,"hasAutofocus":_vm.hasAutofocus,"hasTorch":_vm.hasTorch,"hasZoom":_vm.hasZoom,"cameraDetails":_vm.cameraDetails,"landscape":_vm.landscape,"torch":_vm.torch,"zoom":Number(_vm.zoom),"autofocus":_vm.autofocus,"focus-distance":Number(_vm.focusDistance),"device-index":_vm.deviceIndex},on:{"update:videoDevices":function($event){_vm.videoDevices=$event},"update:video-devices":function($event){_vm.videoDevices=$event},"update:hasFocusDistance":function($event){_vm.hasFocusDistance=$event},"update:has-focus-distance":function($event){_vm.hasFocusDistance=$event},"update:hasAutofocus":function($event){_vm.hasAutofocus=$event},"update:has-autofocus":function($event){_vm.hasAutofocus=$event},"update:hasTorch":function($event){_vm.hasTorch=$event},"update:has-torch":function($event){_vm.hasTorch=$event},"update:hasZoom":function($event){_vm.hasZoom=$event},"update:has-zoom":function($event){_vm.hasZoom=$event},"update:cameraDetails":function($event){_vm.cameraDetails=$event},"update:camera-details":function($event){_vm.cameraDetails=$event},"decode":_vm.onDecode,"loaded":_vm.onLoaded,"result":_vm.onResult}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }