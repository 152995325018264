<template>
    <div id="home">
      <Form></Form>
    </div>
</template>
<script>
import Form from "@/components/Form";
export default {
    components: {
        Form
    },
    data(){
        return{
          
        }
    }
}
</script>
<style>

</style>