<template>
    <div class="books">
        <div>
            <h1 class="title">基因检测知情同意书</h1>
             <p>当您决定接受本次基因检测服务之前，请您仔细阅读以下内容：</p>
            <div class="text">
                <p>1.本基因检测服务基于受检者真实可靠的测序数据，以实现对其健康风险的评估。本检测结果仅供健康管理指导参考，不可作为临床诊断和治疗依据。</p>
                <p>2.我司仅对本次检测所涉及的样本负责，并仅对承诺范围内检测结果及解读结果负责，如送检样本存在检测范围以外的异常（如片段缺失、重复等），可能影响检测结果、解读结果或检测失败，我司保留对报告内容的说明、解释及更新的权利。</p>
                <p>3.如果受检者前期接受过异体输血、移植手术、干细胞治疗等，可能会引入外源DNA，也可能会影响检测结果。</p>
                <p>4.由于不可抗拒因素致使样本损耗（如取样问题等）导致的样本质量不合格，为保证检测结果准确性，受检者需配合检测机构再次取样，检测周期从收取合格样本之日起计算，报告出具时间将顺延；如无法再次取样将停止检测，并不提供检测结果，检测机构不承担任何责任；如受检者坚持要求针对不合格样本继续检测，可能会导致检测失败、检测数据不足或检测结果不准确，检测机构不承担任何责任，检测费用和风险由受检者本人承担。</p>
                <p>5.受检者自愿接受本次基因检测服务，并承诺提供的个人资料信息真实可靠。您应当确认您向我们提供的样本属于您本人，若您向我们提供他人的样本，您应当确认您已经取得了可被证明的合法授权。我司不负责检验您提供样本的真实性和合法性，对于您违反本条规定而造成的任何损失，我司概不承担责任。</p>
                <p>6.我司将严格保密受检者的个人信息及遗传信息；由受检者主动向他人提供或者分享自己的遗传信息或检测结果的，其产生的一切后果由受检者本人承担。</p>
                <p>7.受检者拥有基因检测原始数据及相关解读结果的所有权，在排除能够识别个人身份的信息后，检测数据可用于科学研究。</p>
                <p>8.基因与生命健康的关系解读是一个长期过程，基因报告的准确性受生命复杂性、科学研究进展等多种因素的影响，报告中出现与个人感受不一致的内容是正常现象，如果基因报告随着科研进展升级可能会出现报告结果的变化。</p>
                <p></p>
                <p></p>
            </div>
            <p>本人已经仔细阅读上述说明，清楚以上各条款的陈述内容，已经被告知充分了解该基因检测的性质、预期目的、局限性和风险。本人对上述内容均认可，并已享有充分的知情权和选择权。</p>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
        }
    },
    methods:{

    }
}
</script>
<style scoped>
   .books>div>div{
       margin: 20px 0;
   }
  .books{
      font-size: 14px;
      padding: 0  20px;
  }
  .title{
      text-align: center;
  }
  .text{
     color: rgb(102, 107, 107);
  }

</style>