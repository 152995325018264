import home from '../views/home.vue'
import index from '../views/index.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    {
      path: '/home',
      name: 'home',
      component: home,
    },
    {
        path: '/',
        name: 'index',
        component: index
    }
  ]
  
  const router = new VueRouter({
    routes
  })
  
  export default router
